.main-search::placeholder{
    font-size: 64px;
    font-family: "Helvetica Neue Bold";
    color: #707070;
}

.main-search{
    font-size: 64px;
    font-family: "Helvetica Neue Bold";
}

.main-search:focus{
    outline: unset !important;
    box-shadow: unset !important;
}

.search-result-div{
    box-shadow: 0 3px 6px #00000029;
    border: 1px solid #C6C6C6;
}
