@font-face {
    font-family: 'Helvetica Neue Regular';
    src: url('../fonts/HelveticaNeueRegular.ttf') format('truetype');
}
@font-face {
    font-family: 'Helvetica Neue Bold';
    src: url('../fonts/HelveticaNeueBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Helvetica Neue Medium';
    src: url('../fonts/HelveticaNeueMedium.ttf') format('truetype');
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-family: "Helvetica Neue Regular";
    color: #151515;
}

.font-bold{
    font-family: "Helvetica Neue Bold";
}

.font-medium{
    font-family: "Helvetica Neue Medium";
}

.know-us-image{
    background-image: url("../images/know_us.png");
    background-size: 100% 100%;
}

.contact-us-image{
    background-image: url("../images/contact_us.png");
    background-size: 100% 100%;
}

.story-boxes{
    min-width: 296px;
    height: fit-content;
}

#node-popUp {
    display: none;
    position: absolute;
    top: 350px;
    left: 170px;
    z-index: 299;
    width: 250px;
    height: 120px;
    background-color: #f9f9f9;
    border-style: solid;
    border-width: 3px;
    border-color: #5394ed;
    padding: 10px;
    text-align: center;
}
#edge-popUp {
    display: none;
    position: absolute;
    top: 350px;
    left: 170px;
    z-index: 299;
    width: 250px;
    height: 90px;
    background-color: #f9f9f9;
    border-style: solid;
    border-width: 3px;
    border-color: #5394ed;
    padding: 10px;
    text-align: center;
}
